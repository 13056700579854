<template>
  <div class="flex flex-col justify-center">
    <div class="max-h-[35vh] bg-white rounded-lg overflow-y-auto logs styled_list">
      <div v-for="log in logs" class="pb-2.5">
        <div class="flex items-center gap-1">
          <VIcon name="Logos/AvvokaBlue" />
          <VSpan bold size="base">
            {{ localize('answer_title') }} {{ log.fileName }}:
          </VSpan>
        </div>
        <Showdown
          :data="log.body"
          :settings="{
            openLinksInNewWindow: true,
            disableForced4SpacesIndentedSublists: true
          }"
        />
      </div>
      <div v-if="props.loading" class="flex justify-center">
        <ASVG class="h-12 w-12" name="Loading" />
      </div>
      <div v-if="props.error" class="flex flex-col justify-center items-center avv-danger-color p-2.5">
        <p>
          <span class="material-symbols-outlined text-5xl"> error </span>
        </p>
        <p v-text="localize('error')" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Showdown from '../../_abstract/Showdown.vue'
import ASVG from '../../_abstract/ASVG.vue'

const props = withDefaults(
  defineProps<{
    logs?: { fileName: string; body: string }[]
    loading?: boolean
    error?: boolean
  }>(),
  {
    loading: false,
    error: false
  }
)
const localize = useLocalize('documents.excel.import.ai')
</script>
<script lang="ts">
import { defineComponent } from 'vue'
import VIcon from '@component-library/labels/VIcon.vue';
import { useLocalize } from '@component-utils/localization';
import VSpan from '@component-library/labels/VSpan.vue';
export default defineComponent({ name: 'AIComments' })
</script>
